import { classList } from "@uniit/frontend-utils";
import { useGtagFormSubmit } from "analytics";
import Input from "components-lib/Input";
import { BaseMarkdown } from "components-lib/Markdown";
import { useStatusMessages } from "contexts/StatusMessages";
import { Formik, Form } from "formik";
import { graphql } from "gatsby";
import MessageIcon from "images/icons/MessageIcon";
import { useFormatMessage } from "internationalization";
import React from "react";
import { FormattedMessage } from "react-intl";
import { NEWSLETTER_SUBSCRIPTION_API_URL } from "shared/constants";
import * as styles from "./NewsletterForm.module.css";

/**
 * @typedef {{
 *  email: string;
 *  }} NewsletterFormValues
 */

/**
 * @param {DataProps<Queries.NewsletterFormFragment> & {
 *  layout?: "horizontal" | "vertical";
 *  onSuccess?: () => void;
 *  className?: string;
 *  articleNewsletter?: "default" | "article";
 * }} props
 */

function NewsletterForm({
  data,
  layout = "horizontal",
  onSuccess,
  className,
  articleNewsletter = "default",
}) {
  const formatMessage = useFormatMessage();

  const { dispatchMessage, dispatchGenericError } = useStatusMessages();

  const fireGtagFormSubmitEvent = useGtagFormSubmit("Newsletter Form");

  /**
   * @param {NewsletterFormValues} values
   * @param {import("formik").FormikHelpers<NewsletterFormValues>} formikHelpers
   */
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await fetch(NEWSLETTER_SUBSCRIPTION_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            email: values.email,
          },
        }),
      });

      if (!response.ok) {
        dispatchGenericError("newsletter-subscription-error");
      } else {
        if (layout === "vertical") {
          onSuccess?.();
        } else {
          dispatchMessage({
            id: "newsletter-form-success",
            content: formatMessage("newsletter-form-success-message"),
            type: "success",
          });
        }

        fireGtagFormSubmitEvent(values);
        resetForm();
      }
    } catch (error) {
      console.error(error);
      dispatchGenericError("newsletter-subscription-error");
    }
  };

  return (
    <section
      className={classList`${styles.newsletter} ${className}`}
      data-layout={layout}
      data-article-newsletter={articleNewsletter}
    >
      <h2 className={styles.title}>
        {data?.title}
        <hr />
      </h2>
      <BaseMarkdown className={styles.description}>
        {articleNewsletter == "article"
          ? data?.inArticleDescription?.data?.inArticleDescription
          : data?.description?.data?.description}
      </BaseMarkdown>
      <Formik initialValues={{ email: "" }} onSubmit={handleSubmit}>
        <Form className={styles.form}>
          <fieldset className={styles.inputWrapper}>
            <Input
              id="newsletter-email"
              aria-label="E-mail"
              autoComplete="email"
              className={styles.emailInput}
              inputMode="email"
              name="email"
              placeholder={formatMessage("email-input-placeholder")}
              required
              type="email"
            />
            <button
              className={styles.sendButton}
              title={formatMessage("subscribe")}
              type="submit"
            >
              <span className={styles.sendButtonText}>
                <FormattedMessage id="send-button" />{" "}
              </span>
              <MessageIcon className={styles.sendButtonIcon} />
            </button>
          </fieldset>
        </Form>
      </Formik>
    </section>
  );
}

export const query = graphql`
  fragment NewsletterForm on STRAPI_NEWSLETTER_FORM {
    title
    description {
      data {
        description
      }
    }
    inArticleDescription {
      data {
        inArticleDescription
      }
    }
  }
`;

export default NewsletterForm;
