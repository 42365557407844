import React from "react";

/**
 * @param {import("react").SVGAttributes<HTMLOrSVGElement>} props
 */
function MessageIcon(props) {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 17 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.346 8.257C16.3457 8.13006 16.3091 8.00585 16.2407 7.89897C16.1722 7.79208 16.0746 7.70697 15.9594 7.65363L1.81802 1.05431C1.69466 0.998796 1.55733 0.982126 1.42427 1.00651C1.29122 1.0309 1.16873 1.09519 1.07307 1.19084C0.97742 1.2865 0.913131 1.40899 0.888744 1.54204C0.864357 1.6751 0.881026 1.81243 0.936539 1.93579L3.36886 7.60178L9.35539 7.59235L9.35539 8.92164L3.35001 8.92165L0.922397 14.5924C0.86962 14.715 0.854885 14.8506 0.880098 14.9818C0.905311 15.1129 0.969313 15.2334 1.06381 15.3277C1.16102 15.421 1.28423 15.4827 1.41719 15.5045C1.55014 15.5264 1.68661 15.5075 1.80859 15.4503L15.95 8.85094C16.0655 8.79966 16.1641 8.71659 16.2342 8.61143C16.3043 8.50627 16.343 8.38335 16.346 8.257Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default MessageIcon;
