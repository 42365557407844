import { classList } from "@uniit/frontend-utils";
import Markdown from "components-lib/Markdown";
import { graphql } from "gatsby";
import mailIcon from "images/icons/mail.svg";
import phoneIcon from "images/icons/phone.svg";
import { useFormatMessage } from "internationalization";
import React from "react";
import * as styles from "./Contact.module.css";

/**
 * @param {BaseProps<DataProps<Queries.ContactInfoFragment>> & {
 *  contactListClassName?: string;
 *  hideAddress?: boolean;
 *  hideLegal?: boolean;
 * }} props
 */
function Contact({
  className,
  contactListClassName,
  hideAddress = false,
  hideLegal = false,
  tabIndex,
  data,
}) {
  const formatMessage = useFormatMessage();

  const { email, phoneNumber } = data ?? {};

  return (
    <address className={`${className} ${styles.contact}`}>
      {!hideAddress && <Markdown data={data} field="address" />}
      {!hideLegal && <Markdown data={data} field="legalInfo" />}
      <ul className={classList`${contactListClassName} ${styles.contactList}`}>
        <li>
          <a href={`tel:${phoneNumber}`} tabIndex={tabIndex}>
            <img alt={formatMessage("phone-icon")} src={phoneIcon} />
            <p>{phoneNumber}</p>
          </a>
        </li>
        <li>
          <a href={`mailto:${email}`} tabIndex={tabIndex}>
            <img alt={formatMessage("mail-icon")} src={mailIcon} />
            <p>{email}</p>
          </a>
        </li>
      </ul>
    </address>
  );
}

export const query = graphql`
  fragment ContactInfo on STRAPI_CONTACT_INFORMATION {
    email
    phoneNumber
    address {
      data {
        address
      }
    }
    legalInfo {
      data {
        legalInfo
      }
    }
    ...ContactMapInfo
  }
`;

export default Contact;
