import { graphql } from "gatsby";
import React from "react";
import * as styles from "./SocialLinks.module.css";

/**
 * @param {BaseProps & DataProps<Queries.SocialLinkNodesFragment>} props
 */
function SocialLinks({ className, tabIndex, data }) {
  return (
    <ul className={`${className} ${styles.socialLinks}`}>
      {data?.nodes.map((link) => (
        <li key={link.name}>
          <a
            aria-label={link.description ?? ""}
            href={link.URL ?? ""}
            tabIndex={tabIndex}
          >
            <img alt="" src={link.icon?.localFile?.publicURL ?? ""} />
          </a>
        </li>
      ))}
    </ul>
  );
}

export const query = graphql`
  fragment SocialLinkNodes on STRAPI_SOCIAL_LINKConnection {
    nodes {
      name
      description
      URL
      icon {
        localFile {
          publicURL
        }
      }
    }
  }
`;

export default SocialLinks;
