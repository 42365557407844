import { classList } from "@uniit/frontend-utils";
import React from "react";

export const types = undefined;
/**
 * @typedef {1 | 2 | 3 | 4 | 5 |6} HeadingLevel;
 * @typedef {"h1" | "h2" | "h3" | "h4" | "h5" | "h6"} HeadingStyle;
 */

/**
 * @typedef {BaseProps<{
 *  level?: HeadingLevel;
 *  style?: HeadingStyle;
 *  "sr-only"?: boolean;
 * }>} HeadingProps
 *
 * @param {HeadingProps} props
 */
function Heading({
  children,
  className,
  level = 2,
  "sr-only": srOnly,
  style = `h${level}`,
}) {
  const HeadingElement =
    /** @type {import("react").FunctionComponent<any>} */
    (/** @type {unknown} */ (`h${level}`));

  return (
    <HeadingElement
      className={classList`${className} ${srOnly ? "sr-only" : undefined}`}
      data-as={style}
    >
      {children}
    </HeadingElement>
  );
}

Heading.propTypes = {};

export default Heading;
