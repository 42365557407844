const LOCALE = /** @type {const} */ ({
  EN: "en",
  SK: "sk",
  UA: "ua",
});

const LOCALE_MAP = /** @type {const} */ ({
  en: "en",
  sk: "sk",
  ua: "uk",
});

const LOCALE_REVERSE_MAP = /** @type {const} */ ({
  en: "en",
  sk: "sk",
  uk: "ua",
});

const DEFAULT_LOCALE = LOCALE.SK;

module.exports = {
  LOCALE,
  LOCALE_MAP,
  LOCALE_REVERSE_MAP,
  DEFAULT_LOCALE,
};
