import StatusMessage from "components-lib/StatusMessage";
import { useStatusMessages } from "contexts/StatusMessages";
import React from "react";
import * as styles from "./StatusMessages.module.css";

function StatusMessages() {
  const { messages, removeMessage } = useStatusMessages();

  return (
    <section className={styles.statusMessages}>
      {messages.map((message, index) => (
        <StatusMessage
          key={`status-message-${message.type}-${index}`}
          isOpen
          onClose={() => removeMessage(message)}
          type={message.type}
        >
          {message.content}
        </StatusMessage>
      ))}
    </section>
  );
}

export default StatusMessages;
