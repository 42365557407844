import { classList } from "@uniit/frontend-utils";
import LocalizedLink from "components-lib/LocalizedLink";
import { graphql } from "gatsby";
import { useIsUkrainian } from "internationalization";
import { kebabCase } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { composeSlugPath } from "shared/utils";
import * as styles from "./ServiceTile.module.css";

/**
 * @param {DataProps<Queries.ServiceTileFragment> & {
 *  type?: "default" | "compact"
 *  className?: string
 * }} props
 */
function ServiceTile({ data, className, type = "default" }) {
  const { name, fields, icon } = data ?? {};

  const isUkrainian = useIsUkrainian();
  const nameId = kebabCase(name ?? "");

  const cn = classList(
    styles.service,
    isUkrainian ? styles.serviceDisabled : "",
    type !== "default" ? styles[type] : "",
    className,
  );

  return (
    <li aria-describedby={nameId}>
      <LocalizedLink className={cn} to={composeSlugPath(fields)}>
        <img
          alt=""
          className={styles.serviceImage}
          src={icon?.localFile?.publicURL ?? ""}
        />
        <p className={styles.serviceName} id={nameId}>
          {name}
        </p>
        {!isUkrainian && (
          <span className={styles.serviceLink}>
            <FormattedMessage id="service-detail-link" />
          </span>
        )}
      </LocalizedLink>
    </li>
  );
}

export const query = graphql`
  fragment ServiceTile on STRAPI_SERVICE {
    strapi_id
    name
    description {
      data {
        description
      }
    }
    icon {
      localFile {
        publicURL
      }
    }
    fields {
      slug
    }
  }
`;

export default ServiceTile;
