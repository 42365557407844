import React from "react";

/**
 * @param {import("react").SVGAttributes<HTMLOrSVGElement>} props
 */
function CloseIcon(props) {
  return (
    <svg
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3 0.70998C13.2075 0.617276 13.0976 0.543728 12.9766 0.493547C12.8556 0.443366 12.7259 0.417535 12.595 0.417535C12.464 0.417535 12.3343 0.443366 12.2134 0.493547C12.0924 0.543728 11.9825 0.617276 11.89 0.70998L6.99998 5.58998L2.10998 0.699979C2.0174 0.607397 1.90749 0.533957 1.78652 0.483852C1.66556 0.433747 1.53591 0.407959 1.40498 0.407959C1.27405 0.407959 1.1444 0.433747 1.02344 0.483852C0.902472 0.533957 0.792561 0.607397 0.699979 0.699979C0.607397 0.792561 0.533957 0.902472 0.483852 1.02344C0.433747 1.1444 0.407959 1.27405 0.407959 1.40498C0.407959 1.53591 0.433747 1.66556 0.483852 1.78652C0.533957 1.90749 0.607397 2.0174 0.699979 2.10998L5.58998 6.99998L0.699979 11.89C0.607397 11.9826 0.533957 12.0925 0.483852 12.2134C0.433747 12.3344 0.407959 12.464 0.407959 12.595C0.407959 12.7259 0.433747 12.8556 0.483852 12.9765C0.533957 13.0975 0.607397 13.2074 0.699979 13.3C0.792561 13.3926 0.902472 13.466 1.02344 13.5161C1.1444 13.5662 1.27405 13.592 1.40498 13.592C1.53591 13.592 1.66556 13.5662 1.78652 13.5161C1.90749 13.466 2.0174 13.3926 2.10998 13.3L6.99998 8.40998L11.89 13.3C11.9826 13.3926 12.0925 13.466 12.2134 13.5161C12.3344 13.5662 12.464 13.592 12.595 13.592C12.7259 13.592 12.8556 13.5662 12.9765 13.5161C13.0975 13.466 13.2074 13.3926 13.3 13.3C13.3926 13.2074 13.466 13.0975 13.5161 12.9765C13.5662 12.8556 13.592 12.7259 13.592 12.595C13.592 12.464 13.5662 12.3344 13.5161 12.2134C13.466 12.0925 13.3926 11.9826 13.3 11.89L8.40998 6.99998L13.3 2.10998C13.68 1.72998 13.68 1.08998 13.3 0.70998Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CloseIcon;
