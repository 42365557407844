import { useLocation } from "@reach/router";
import Contact from "components/Contact";
import LocaleSwitcher from "components/LocaleSwitcher";
import NavigationLinks from "components/NavigationLinks";
import Search from "components/Search";
import SocialLinks from "components/SocialLinks";
import LocalizedLink from "components-lib/LocalizedLink";
import { useOnMobile } from "contexts/OnMobile";
import CloseIcon from "images/icons/Close";
import SearchIcon from "images/icons/Search";
import logoUA from "images/logo-ua.svg";
import logo from "images/logo.svg";
import { useFormatMessage, useIsUkrainian } from "internationalization";
import React, { useEffect, useState } from "react";
import * as styles from "./Header.module.css";

const SCROLL_THRESHOLD = 50;
const NAV_ID = "primary-nav";

/**
 * @param {DataProps<{
 *  contactInfo: Queries.ContactInfoFragment | undefined;
 *  socialLinks: Queries.SocialLinkNodesFragment | undefined;
 *  services: Queries.ServiceNodesFragment | undefined;
 * } & Partial<Queries.SearchQueriesFragment>>} props
 */
function Header({ data }) {
  const { onMobile } = useOnMobile();

  const [navToggled, setNavToggled] = useState(false);
  const [pinned, setPinned] = useState(true);

  const [searchToggled, setSearchToggled] = useState(false);

  const formatMessage = useFormatMessage();
  const location = useLocation();

  const isUkrainian = useIsUkrainian();

  const tabIndex = navToggled ? 0 : -1;

  useEffect(() => setNavToggled(!onMobile), [onMobile]);
  useEffect(() => setNavToggled(false), [location]);

  useEffect(() => {
    if (!onMobile) {
      setNavToggled(true);
    }
  }, [navToggled, onMobile]);

  useEffect(() => {
    const checkScrollPos = () => {
      setPinned(Math.round(window.scrollY) < SCROLL_THRESHOLD);
      window.requestAnimationFrame(checkScrollPos);
    };

    window.requestAnimationFrame(checkScrollPos);
  }, []);

  useEffect(() => {
    setSearchToggled(false);
  }, [location.pathname]);

  return (
    <header
      className={styles.header}
      data-pinned={pinned}
      data-reduced={isUkrainian}
      id="header"
    >
      <LocalizedLink className={styles.logo} to="/">
        <img
          alt="SEMANČÍN & PARTNERS"
          className={styles.logoImg}
          src={isUkrainian ? logoUA : logo}
        />
      </LocalizedLink>
      <button
        className={styles.searchToggleButton}
        data-search-toggled={searchToggled}
        onClick={() => {
          setSearchToggled(!searchToggled);

          if (onMobile && navToggled) {
            setNavToggled(false);
          }
        }}
        aria-label={"Toggle search input"}
      >
        <SearchIcon className={styles.searchIcon} />
        <CloseIcon className={styles.closeIcon} />
      </button>
      <Search
        data={data}
        open={searchToggled}
        onClose={() => setSearchToggled(false)}
      />
      {!isUkrainian && (
        <button
          aria-controls={NAV_ID}
          aria-expanded={navToggled}
          aria-label={formatMessage("nav-toggle-aria-label")}
          className={styles.toggleButton}
          data-toggled={navToggled}
          onClick={() => {
            setNavToggled(!navToggled);

            if (onMobile && searchToggled) {
              setSearchToggled(false);
            }
          }}
          type="button"
        >
          <span />
          <span />
          <span />
        </button>
      )}
      {!isUkrainian && (
        <nav aria-hidden={!navToggled} className={styles.nav} id={NAV_ID}>
          <NavigationLinks
            className={styles.navLinks}
            tabIndex={tabIndex}
            data={data?.services}
          />
          <hr />
          <SocialLinks
            className={styles.socialLinks}
            tabIndex={tabIndex}
            data={data?.socialLinks}
          />
          <LocaleSwitcher
            className={styles.localeSwitcher}
            tabIndex={tabIndex}
          />
          <Contact
            data={data?.contactInfo}
            className={styles.contact}
            contactListClassName={styles.contactList}
            hideAddress
            hideLegal
            tabIndex={tabIndex}
          />
        </nav>
      )}
    </header>
  );
}

export default Header;
