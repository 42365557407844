import React from "react";

/**
 * @param {import("react-markdown/lib/ast-to-react").TableDataCellProps} props
 */
function MarkdownTableCell({ children, ...props }) {
  return (
    <td {...props}>
      {[children]
        .flat()
        .map((child, index) =>
          ["<br>", "<br />", "<br/>"].includes(child?.toString() ?? "") ? (
            <br
              key={`markdown-table-cell-br-${index}-${Math.floor(
                Math.random() * 31 ** 13,
              )}`}
            />
          ) : (
            child
          ),
        )}
    </td>
  );
}

export default MarkdownTableCell;
