import React from "react";

/**
 * @param {Queries.TeamMemberNamePartsFragment | null | undefined} nameParts
 */
export function formatTeamMemberName(nameParts) {
  const { prefixTitles, givenName, familyName, suffixTitles } = nameParts ?? {};

  return [prefixTitles, givenName, familyName, suffixTitles]
    .filter(Boolean)
    .join(" ");
}

/**
 * @param {DataProps<Queries.TeamMemberNamePartsFragment>} props
 */
function TeamMemberName({ data }) {
  const name = formatTeamMemberName(data);
  return <>{name}</>;
}

export default TeamMemberName;
