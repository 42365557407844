import { classList } from "@uniit/frontend-utils";
import ServiceTile from "components/ServiceTile";
import Section from "components-lib/Section";
import React from "react";
import * as styles from "./ServicesSubcategories.module.css";

/**
 * @param {import("components-lib/Section").SectionProps &
 *  DataProps<Queries.ServiceNodesFragment>} props
 */

function ServicesSubcategories({ data, className, ...props }) {
  if (data?.nodes.length === 0) return null;

  return (
    <Section
      className={classList`${className} ${styles.servicesSubcategoriesWrapper}`}
      {...props}
    >
      <ul className={styles.servicesSubcategories}>
        {data?.nodes.map((service, index) => (
          <ServiceTile
            type="compact"
            data={service}
            key={`service-${index}`}
            className={styles.serviceSubcategoriesTile}
          />
        ))}
      </ul>
    </Section>
  );
}

export default ServicesSubcategories;
