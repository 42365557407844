import { useLocation } from "@reach/router";
import { classList } from "@uniit/frontend-utils";
import ServicesSubcategories from "components/ServicesSubcategories";
import LocalizedLink from "components-lib/LocalizedLink";
import downArrowIconSrc from "images/icons/down-arrow.svg";
import { useLocalizedNavLinks } from "internationalization";
import React, { Children, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import * as styles from "./NavigationLinks.module.css";

/**
 * @param {BaseProps & DataProps<Queries.ServiceNodesFragment> & {style?: "header" | "footer"} } props
 */
function NavigationLinks({
  children,
  className,
  tabIndex,
  data,
  style = "header",
}) {
  const [isNavigating, setIsNavigating] = useState(false);
  const navLinks = useLocalizedNavLinks();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith(navLinks.services)) {
      setIsNavigating(true);
      return;
    }

    setIsNavigating(true);
    setTimeout(() => setIsNavigating(false), 300);
  }, [navLinks, location.pathname]);

  return (
    <ul
      className={classList`${className} ${styles.primaryLinks}`}
      data-style={style}
      data-is-navigating={isNavigating}
    >
      <li>
        <LocalizedLink tabIndex={tabIndex} to={navLinks["about-us"]}>
          <FormattedMessage id="about-us-title" />
        </LocalizedLink>
      </li>
      <li className={styles.servicesLink}>
        <LocalizedLink
          tabIndex={tabIndex}
          to={navLinks.services}
          onMouseLeave={() => setIsNavigating(false)}
        >
          <FormattedMessage id="services-title" />
        </LocalizedLink>
        <img src={downArrowIconSrc} />
        <div className={styles.backdrop} />
        <ServicesSubcategories
          data={data}
          className={styles.serviceSubcategories}
        />
      </li>
      <li>
        <LocalizedLink tabIndex={tabIndex} to={navLinks.references}>
          <FormattedMessage id="references-title" />
        </LocalizedLink>
      </li>
      <li>
        <LocalizedLink
          tabIndex={tabIndex}
          to={navLinks["awards-and-memberships"]}
        >
          <FormattedMessage id="awards-title" />
        </LocalizedLink>
      </li>
      <li className={styles.hrListItem}>
        <hr />
      </li>
      <li>
        <LocalizedLink tabIndex={tabIndex} to={navLinks.blog}>
          <FormattedMessage id="blog-title" />
        </LocalizedLink>
      </li>
      <li>
        <LocalizedLink tabIndex={tabIndex} to={navLinks.career}>
          <FormattedMessage id="career-title" />
        </LocalizedLink>
      </li>
      <li>
        <LocalizedLink tabIndex={tabIndex} to={navLinks.contact}>
          <FormattedMessage id="contact-title" />
        </LocalizedLink>
      </li>
      {Children.map(children, (child, index) => (
        <li key={`additional-nav-link-${index}`} tabIndex={tabIndex}>
          {child}
        </li>
      ))}
    </ul>
  );
}

export default NavigationLinks;
