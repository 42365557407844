import { useDefaultConsentScriptContent } from "@uniit/cookie-consent";
import CookieConsentBanner from "components/CookieConsentBanner";
import Footer from "components/Footer";
import Header from "components/Header";
import NewsletterForm from "components/NewsletterForm";
import StatusMessages from "components/StatusMessages";
import GtagPageViewProvider from "contexts/GtagPageView";
import { OnMobileProvider } from "contexts/OnMobile";
import { StatusMessagesProvider } from "contexts/StatusMessages";
import {
  LocaleContext,
  LOCALE,
  useMessages,
  useDefaultLocale,
  PageI18nContext,
} from "internationalization";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";
import * as styles from "./index.module.css";

const GTAG_ID = "GTM-NFGBSC7";

/**
 * @param {import("react").PropsWithChildren & import("gatsby").PageProps<
 *  {
 *    contactInfo: Queries.ContactInfoFragment | undefined;
 *    cookieConsentBanner: Queries.CookieConsentBannerFragment | undefined;
 *    newsletterForm: Queries.NewsletterFormFragment | undefined;
 *    contactForm: Queries.ContactFormFragment | undefined;
 *    socialLinks: Queries.SocialLinkNodesFragment | undefined;
 *    services: Queries.ServiceNodesFragment | undefined;
 *  } & Partial<Queries.SearchQueriesFragment>,
 *  PageI18nContext
 * >} props
 */
function Layout({ pageContext, data, children }) {
  const { langKey } = pageContext;

  const [locale, setLocale] = useState(langKey);

  const defaultLocale = useDefaultLocale();
  const messages = useMessages(locale || defaultLocale);

  const consentScript = useDefaultConsentScriptContent();

  const isUkrainian = locale === "ua";

  useEffect(() => {
    setLocale(langKey);
  }, [langKey]);

  return (
    <PageI18nContext.Provider value={pageContext}>
      <LocaleContext.Provider value={[locale, setLocale]}>
        <IntlProvider locale={locale || defaultLocale} messages={messages}>
          <GtagPageViewProvider>
            <OnMobileProvider>
              <Helmet htmlAttributes={{ lang: locale }}>
                <meta charSet="utf-8" />
                <meta
                  content="p0sj25sfg8drxfv8mfvkd7uxdhmjry"
                  name="facebook-domain-verification"
                />
                <meta
                  content="aK0c2U7av-QMQTA5zx701R58jzPWABLOQnE5R6twUW0"
                  name="google-site-verification"
                />
                <script type="application/ld+json">
                  {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://semancin.sk${
                  locale === LOCALE.EN ? "/en" : ""
                }",
                "name": "${messages["page-name"]}",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+421 2 32 609 451",
                  "email": "office@semancin.sk",
                  "contactType": "Customer Support"
                }
              }
            `}
                </script>
                <script async>{consentScript}</script>
                <script>
                  {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTAG_ID}');
            `}
                </script>
              </Helmet>
              <noscript>
                <iframe
                  height="0"
                  src={`https://www.googletagmanager.com/ns.html?id=${GTAG_ID}`}
                  style={{
                    display: "none",
                    visibility: "hidden",
                  }}
                  title="gtag"
                  width="0"
                />
              </noscript>
              <StatusMessagesProvider>
                <Header data={data} />
                <main>
                  <div id={styles.content}>{children}</div>
                  {!isUkrainian && (
                    <NewsletterForm data={data.newsletterForm} />
                  )}
                </main>
                <Footer data={data} />
                <CookieConsentBanner data={data.cookieConsentBanner} />
                <StatusMessages />
              </StatusMessagesProvider>
            </OnMobileProvider>
          </GtagPageViewProvider>
        </IntlProvider>
      </LocaleContext.Provider>
    </PageI18nContext.Provider>
  );
}

export default Layout;
