/* eslint-disable no-undef */
import { useCookieConsent } from "@uniit/cookie-consent";
import Button, { OutlinedButton } from "components-lib/Button";
import LocalizedLink from "components-lib/LocalizedLink";
import Markdown from "components-lib/Markdown";
import { useStatusMessages } from "contexts/StatusMessages";
import { Formik, Form } from "formik";
import { graphql } from "gatsby";
import CloseIcon from "images/icons/Close";
import {
  useFormatMessage,
  useIsUkrainian,
  useLocalizedNavLinks,
} from "internationalization";
import { isEmpty } from "lodash";
import React, { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { COOKIE_CONSENT_API_URL } from "shared/constants";
import * as styles from "./CookieConsentBanner.module.css";

/**
 * @typedef {{
 *  ad: boolean;
 *  analytics: boolean;
 * }} CookieConsentBannerValues
 */

/**
 * @param {DataProps<Queries.CookieConsentBannerFragment>} props
 */
function CookieConsentBanner({ data }) {
  const [updateConsent, consentState] = useCookieConsent({
    initialState: {},
  });

  const dialogRef = useRef(/** @type {HTMLDialogElement | null} */ (null));

  const navLinks = useLocalizedNavLinks();
  const isUkrainian = useIsUkrainian();

  const formatMessage = useFormatMessage();
  const { dispatchGenericError } = useStatusMessages();

  function handleDecline() {
    updateConsent({
      "gtag.ad": "denied",
      "gtag.analytics": "denied",
      "fb-pixel": "denied",
    });

    dialogRef.current?.close();
  }

  /**
   * @param {any} error
   */
  function handleSubmitError(error) {
    console.error(error);
    dispatchGenericError("cookie-consent-error");
  }

  /**
   * @param {CookieConsentBannerValues} values
   */
  async function handleSubmit(values) {
    const { ad, analytics } = values;

    try {
      const response = await fetch(COOKIE_CONSENT_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            userAgent: navigator.userAgent,
            analyticsConsent: analytics,
            marketingConsent: ad,
          },
        }),
      });

      if (!response.ok) {
        handleSubmitError(response.statusText);
        return;
      }

      updateConsent({
        "gtag.ad": ad ? "granted" : "denied",
        "gtag.analytics": analytics ? "granted" : "denied",
        "fb-pixel": analytics ? "granted" : "denied",
      });
    } catch (error) {
      handleSubmitError(error);
    }
  }

  useEffect(() => {
    const hasConsent = !isEmpty(consentState);

    if (!hasConsent) {
      dialogRef.current?.show();
    } else {
      dialogRef.current?.close();
    }
  }, [consentState]);

  return (
    <dialog ref={dialogRef} className={styles.cookieConsentBanner}>
      <Formik
        enableReinitialize
        initialValues={{
          ad: true,
          analytics: true,
        }}
        onSubmit={handleSubmit}
      >
        <Form className={styles.cookieConsentForm} method="dialog">
          <div className={styles.consentHeader}>
            <p className={styles.consentTitle}>{data?.title}</p>
            <button
              aria-label={formatMessage("decline-cookies-aria-label")}
              className={styles.declineButton}
              onClick={handleDecline}
              type="button"
            >
              <CloseIcon />
            </button>
          </div>
          <Markdown className={styles.cookiesInfo} data={data} field="body" />
          <div className={styles.consentActions}>
            {!isUkrainian && (
              <LocalizedLink
                to={`${navLinks["personal-data-protection"]}#settings`}
              >
                <FormattedMessage id="cookie-consent-settings" />
              </LocalizedLink>
            )}
            <OutlinedButton
              className={styles.consentButton}
              onClick={handleDecline}
              type="button"
            >
              <FormattedMessage id="cookie-consent-reject" />
            </OutlinedButton>
            <Button className={styles.consentButton} type="submit">
              <FormattedMessage id="cookie-consent-accept" />
            </Button>
          </div>
        </Form>
      </Formik>
    </dialog>
  );
}

export const query = graphql`
  fragment CookieConsentBanner on STRAPI_COOKIE_CONSENT_BANNER {
    body {
      data {
        body
      }
    }
    title
  }
`;

export default CookieConsentBanner;
