import { snakeCase } from "lodash";
import { useCallback } from "react";

/**
 * @param {string} label
 */
function useGtagFormSubmit(label) {
  return useCallback(
    /**
     * @param {Record<string, string>} values
     */
    (values) => {
      if (!("gtag" in window && typeof window.gtag === "function")) {
        return;
      }

      const formattedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [snakeCase(key), value]),
      );

      window.gtag("event", "form_submit", {
        event_category: "Form Submissions",
        event_action: "Form Submitted",
        event_label: label,
        event_location: window.location.href,
        event_data: formattedValues,
      });
    },
    [label],
  );
}

export default useGtagFormSubmit;
