import PropTypes from "prop-types";
import React from "react";
import intrinsicElementsList from "./intrinsic-elements-list";

export const types = undefined;
/**
 * @typedef {typeof import("./intrinsic-elements-list").default[number]} IntrinsicElementName
 */

// eslint-disable-next-line react/display-name
const IntrinsicElementWrapper = React.forwardRef(
  /**
   * @param {BaseProps<{ as: IntrinsicElementName; } & Record<string, any>>} props
   */
  ({ as, children, ...props }, ref) => {
    const IntrinsicElement = /** @type {"div"} */ (as);

    return (
      <IntrinsicElement ref={ref} {...props}>
        {children}
      </IntrinsicElement>
    );
  },
);

IntrinsicElementWrapper.propTypes = {
  as: PropTypes.oneOf(intrinsicElementsList).isRequired,
  children: PropTypes.node,
};

export default IntrinsicElementWrapper;
