const API_URL = process.env.GATSBY_STRAPI_API_URL;

/**
 * @param {string} path
 * @returns {URL}
 */
function composeApiUrl(path) {
  return new URL(path, API_URL);
}

const COOKIE_CONSENT_API_URL = composeApiUrl("/api/cookie-consents");
const CONTACT_FORM_API_URL = composeApiUrl("/api/contact-form-submissions");
const NEWSLETTER_SUBSCRIPTION_API_URL = composeApiUrl(
  "/api/newsletter-subscriptions",
);

const NAV_LINK_TRANSLATIONS = Object.freeze({
  en: {
    blog: "/blog",
    career: "/career",
    contact: "/contact",
    "about-us": "/about-us",
    "awards-and-memberships": "/awards-and-memberships",
    "personal-data-protection": "/personal-data-protection",
    references: "/references",
    services: "/services",
    "consent-to-the-processing-of-personal-data":
      "/consent-to-the-processing-of-personal-data",
  },
  sk: {
    blog: "/blog",
    career: "/kariera",
    contact: "/kontakt",
    "about-us": "/o-nas",
    "awards-and-memberships": "/ocenenia-a-clenstva",
    "personal-data-protection": "/ochrana-osobnych-udajov",
    references: "/referencie",
    services: "/sluzby",
    "consent-to-the-processing-of-personal-data":
      "/suhlas-so-spracovanim-osobnych-udajov",
  },
  ua: {
    blog: "/",
    career: "/",
    contact: "/",
    "about-us": "/",
    "awards-and-memberships": "/",
    "personal-data-protection": "/",
    references: "/",
    services: "/",
    "consent-to-the-processing-of-personal-data": "/",
  },
});

module.exports = {
  NAV_LINK_TRANSLATIONS,
  COOKIE_CONSENT_API_URL,
  NEWSLETTER_SUBSCRIPTION_API_URL,
  CONTACT_FORM_API_URL,
};
