import { classList } from "@uniit/frontend-utils";
import React from "react";
import * as styles from "./StatusMessage.module.css";

/**
 * @param {BaseProps<{
 *  isOpen?: boolean;
 *  onClose?: () => void;
 *  type?: import("contexts/StatusMessages").StatusMessageType;
 * }>} props
 */
function StatusMessage({
  children,
  className = "",
  isOpen = false,
  onClose,
  type = "success",
}) {
  return (
    <dialog
      className={classList`${className} ${styles[`${type}Message`]}`}
      onClick={onClose}
      open={isOpen}
    >
      {children}
    </dialog>
  );
}

export default StatusMessage;
