import React from "react";
import BaseMarkdown from "./BaseMarkdown";

/**
 * @template {string} K
 * @typedef {{
 *  [Key in K]: {
 *    data: { [InnerKey in K]: string | null } | null
 *  } | null
 * }} ObjectWithMarkdownField
 */

/**
 * @template {ObjectWithMarkdownField<K>} T
 * @template {keyof T & string} K
 * @param {T | null | undefined} data;
 * @param {K} field
 * @returns {string}
 */
export function getMarkdownField(data, field) {
  return data?.[field]?.data?.[field] ?? "";
}

/**
 * @template {ObjectWithMarkdownField<K>} T
 * @template {keyof T & string} K
 * @param {{
 *  className?: string;
 *  data: T | null | undefined;
 *  field: K;
 *  limit?: number | boolean;
 * }} props
 */
function Markdown({ className, data, field, limit }) {
  const markdownField = getMarkdownField(data, field);

  return (
    <BaseMarkdown className={className} limit={limit}>
      {markdownField}
    </BaseMarkdown>
  );
}

export default Markdown;
