exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-ua-js": () => import("./../../../src/pages/404.ua.js" /* webpackChunkName: "component---src-pages-404-ua-js" */),
  "component---src-pages-about-us-index-en-js": () => import("./../../../src/pages/about-us/index.en.js" /* webpackChunkName: "component---src-pages-about-us-index-en-js" */),
  "component---src-pages-awards-and-memberships-index-en-js": () => import("./../../../src/pages/awards-and-memberships/index.en.js" /* webpackChunkName: "component---src-pages-awards-and-memberships-index-en-js" */),
  "component---src-pages-blog-index-en-js": () => import("./../../../src/pages/blog/index.en.js" /* webpackChunkName: "component---src-pages-blog-index-en-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-career-index-en-js": () => import("./../../../src/pages/career/index.en.js" /* webpackChunkName: "component---src-pages-career-index-en-js" */),
  "component---src-pages-consent-to-the-processing-of-personal-data-index-en-js": () => import("./../../../src/pages/consent-to-the-processing-of-personal-data/index.en.js" /* webpackChunkName: "component---src-pages-consent-to-the-processing-of-personal-data-index-en-js" */),
  "component---src-pages-contact-index-en-js": () => import("./../../../src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-ua-js": () => import("./../../../src/pages/index.ua.js" /* webpackChunkName: "component---src-pages-index-ua-js" */),
  "component---src-pages-kariera-index-js": () => import("./../../../src/pages/kariera/index.js" /* webpackChunkName: "component---src-pages-kariera-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-o-nas-index-js": () => import("./../../../src/pages/o-nas/index.js" /* webpackChunkName: "component---src-pages-o-nas-index-js" */),
  "component---src-pages-ocenenia-a-clenstva-index-js": () => import("./../../../src/pages/ocenenia-a-clenstva/index.js" /* webpackChunkName: "component---src-pages-ocenenia-a-clenstva-index-js" */),
  "component---src-pages-ochrana-osobnych-udajov-index-js": () => import("./../../../src/pages/ochrana-osobnych-udajov/index.js" /* webpackChunkName: "component---src-pages-ochrana-osobnych-udajov-index-js" */),
  "component---src-pages-personal-data-protection-index-en-js": () => import("./../../../src/pages/personal-data-protection/index.en.js" /* webpackChunkName: "component---src-pages-personal-data-protection-index-en-js" */),
  "component---src-pages-references-index-en-js": () => import("./../../../src/pages/references/index.en.js" /* webpackChunkName: "component---src-pages-references-index-en-js" */),
  "component---src-pages-referencie-index-js": () => import("./../../../src/pages/referencie/index.js" /* webpackChunkName: "component---src-pages-referencie-index-js" */),
  "component---src-pages-services-index-en-js": () => import("./../../../src/pages/services/index.en.js" /* webpackChunkName: "component---src-pages-services-index-en-js" */),
  "component---src-pages-sluzby-index-js": () => import("./../../../src/pages/sluzby/index.js" /* webpackChunkName: "component---src-pages-sluzby-index-js" */),
  "component---src-pages-suhlas-so-spracovanim-osobnych-udajov-index-js": () => import("./../../../src/pages/suhlas-so-spracovanim-osobnych-udajov/index.js" /* webpackChunkName: "component---src-pages-suhlas-so-spracovanim-osobnych-udajov-index-js" */),
  "component---src-templates-article-article-js": () => import("./../../../src/templates/Article/Article.js" /* webpackChunkName: "component---src-templates-article-article-js" */),
  "component---src-templates-service-service-js": () => import("./../../../src/templates/Service/Service.js" /* webpackChunkName: "component---src-templates-service-service-js" */),
  "component---src-templates-team-member-team-member-js": () => import("./../../../src/templates/TeamMember/TeamMember.js" /* webpackChunkName: "component---src-templates-team-member-team-member-js" */)
}

