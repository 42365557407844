import React, { createContext, useContext, useEffect, useState } from "react";

const BREAKPOINT = 768;

const OnMobileContext = createContext({
  onMobile: false,
});

export function useOnMobile() {
  return useContext(OnMobileContext);
}

/**
 * @param {import("react").PropsWithChildren} props
 */
export function OnMobileProvider({ children }) {
  const [onMobile, setOnMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setOnMobile(window.innerWidth < BREAKPOINT);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <OnMobileContext.Provider value={{ onMobile }}>
      {children}
    </OnMobileContext.Provider>
  );
}
