import Fuse from "fuse.js";
import { useMemo, useState } from "react";

/**
 * @template T
 * @param {T[]} entries
 * @returns {[
 *   search: Fuse<T>["search"],
 *   results: import("fuse.js").FuseResult<T>[],
 *   setResults: import("react").Dispatch<React.SetStateAction<import("fuse.js").FuseResult<T>[]>>
 * ]}
 */
function useSearch(entries) {
  const search = useMemo(() => {
    const fuzzySearch = new Fuse(entries, {
      isCaseSensitive: false,
      shouldSort: true,
      includeMatches: true,
      findAllMatches: true,
      minMatchCharLength: 3,
      ignoreLocation: true,
      ignoreFieldNorm: true,
      keys: ["name", "title", "givenName", "familyName"],
    });

    const searchFn = fuzzySearch.search.bind(fuzzySearch);
    return searchFn;
  }, [entries]);

  const [results, setResults] = useState(
    /** @type {import("fuse.js").FuseResult<T>[]} */ ([]),
  );

  return [search, results, setResults];
}

export default useSearch;
