import { useFormatMessage } from "internationalization";
import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

/**
 * @typedef {"success" | "error"} StatusMessageType
 *
 * @typedef {{
 *  id: string;
 *  content?: import("react").ReactNode;
 *  type?: StatusMessageType
 * }} StatusMessage
 *
 * @typedef {{
 *  messages: StatusMessage[];
 *  dispatchMessage: (message: StatusMessage) => void;
 *  dispatchGenericError: (messageId: string) => void;
 *  removeMessage: (message: StatusMessage) => void;
 * }} StatusMessagesContextType
 */

const StatusMessagesContext = createContext(
  /** @type {StatusMessagesContextType} */ ({
    messages: [],
    dispatchMessage: ({ id, content = "", type = "success" }) => {
      void id;
      void content;
      void type;
    },
    dispatchGenericError: (messageId) => void messageId,
    removeMessage: (message) => void message,
  }),
);

export function useStatusMessages() {
  return useContext(StatusMessagesContext);
}

/**
 * @readonly
 * @enum {string}
 */
export const MESSAGE_ACTION_TYPE = /** @type {const} */ ({
  ADD: "add",
  REMOVE: "remove",
  RESET: "reset",
});

/**
 * @param {import("react").PropsWithChildren} props
 */
export function StatusMessagesProvider({ children }) {
  const [messages, dispatch] = useReducer(
    /**
     * @param {StatusMessage[]} messagesState
     * @param {{
     *  message: StatusMessage;
     *  type: MESSAGE_ACTION_TYPE;
     * }} action
     * @throws {Error}
     * @returns {StatusMessage[]}
     */
    (messagesState, action) => {
      switch (action.type) {
        case MESSAGE_ACTION_TYPE.ADD:
          if (messagesState.some((message) => message.id === action.message.id))
            return messagesState;
          return [...messagesState, action.message];

        case MESSAGE_ACTION_TYPE.REMOVE:
          return messagesState.filter(
            (message) => message.id !== action.message.id,
          );

        case MESSAGE_ACTION_TYPE.RESET:
          return [];

        default:
          throw Error("Unknown message action");
      }
    },
    [],
  );

  const formatMessage = useFormatMessage();

  const dispatchMessage = useCallback(
    /**
     * @param {StatusMessage} message
     */
    (message) => {
      dispatch({ type: MESSAGE_ACTION_TYPE.ADD, message });
    },
    [],
  );

  const removeMessage = useCallback(
    /**
     * @param {StatusMessage} message -
     */
    (message) => {
      dispatch({ type: MESSAGE_ACTION_TYPE.REMOVE, message });
    },
    [],
  );

  const dispatchGenericError = useCallback(
    /**
     * @param {string} messageId
     */
    (messageId) => {
      dispatchMessage({
        id: messageId,
        content: formatMessage("generic-error-message"),
        type: "error",
      });
    },
    [dispatchMessage, formatMessage],
  );

  return (
    <StatusMessagesContext.Provider
      value={{
        messages,
        dispatchMessage,
        dispatchGenericError,
        removeMessage,
      }}
    >
      {children}
    </StatusMessagesContext.Provider>
  );
}
