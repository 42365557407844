// extracted by mini-css-extract-plugin
export var closeIcon = "Header-module--close-icon--b2e09";
export var contact = "Header-module--contact--dfa7f";
export var contactList = "Header-module--contact-list--dfc5c";
export var header = "Header-module--header--aa06a";
export var localeSwitcher = "Header-module--locale-switcher--28491";
export var logo = "Header-module--logo--6e63c";
export var logoImg = "Header-module--logo-img--1b24e";
export var nav = "Header-module--nav--4ab0c";
export var navLinks = "Header-module--nav-links--5d576";
export var searchIcon = "Header-module--search-icon--f3198";
export var searchToggleButton = "Header-module--search-toggle-button--6ff55";
export var socialLinks = "Header-module--social-links--0d9c0";
export var toggleButton = "Header-module--toggle-button--f0095";