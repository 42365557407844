import Contact from "components/Contact";
import LocaleSwitcher from "components/LocaleSwitcher";
import NavigationLinks from "components/NavigationLinks";
import SocialLinks from "components/SocialLinks";
import LocalizedLink from "components-lib/LocalizedLink";
import logoUA from "images/logo-ua.svg";
import logo from "images/logo.svg";
import { useLocalizedNavLinks, useIsUkrainian } from "internationalization";
import React from "react";
import { FormattedMessage } from "react-intl";
import * as styles from "./Footer.module.css";

/**
 * @param {DataProps<{
 *  contactInfo: Queries.ContactInfoFragment | undefined;
 *  socialLinks: Queries.SocialLinkNodesFragment | undefined;
 *  services: Queries.ServiceNodesFragment | undefined;
 * }>} props
 */
function Footer({ data }) {
  const navLinks = useLocalizedNavLinks();
  const isUkrainian = useIsUkrainian();

  return (
    <footer className={styles.footer}>
      <section className={styles.logoAndSocial}>
        <img alt="SEMANČÍN & PARTNERS" src={isUkrainian ? logoUA : logo} />
        <SocialLinks className={styles.socialLinks} data={data?.socialLinks} />
        <LocaleSwitcher />
      </section>
      <section className={styles.contact}>
        <h2 data-as="h4">
          <FormattedMessage id="contact-title" />
        </h2>
        <Contact
          data={data?.contactInfo}
          className={styles.contactList}
          hideLegal
        />
      </section>
      {!isUkrainian && (
        <section className={styles.links}>
          <h2 data-as="h4">
            <FormattedMessage id="links-title" />
          </h2>
          <div className={styles.linksList}>
            <NavigationLinks
              className={styles.navLinks}
              data={data?.services}
              style="footer"
            >
              <LocalizedLink to={navLinks["personal-data-protection"]}>
                <FormattedMessage id="personal-data-protection-title-alt" />
              </LocalizedLink>
            </NavigationLinks>
          </div>
          <hr />
        </section>
      )}
      <section className={styles.copyright}>
        <small>
          <FormattedMessage
            id="copyright"
            values={{ year: new Date().getFullYear() }}
          />
        </small>
        <small>
          <FormattedMessage id="developer-note" />
        </small>
      </section>
    </footer>
  );
}

export default Footer;
