import { LOCALES, useFormatMessage } from "internationalization";
import { useEffect } from "react";

/**
 * @param {import("react").PropsWithChildren} props
 */
function GtagPageViewProvider({ children }) {
  const formatMessage = useFormatMessage();

  useEffect(() => {
    /**
     * @param {MouseEvent} event
     */
    function handleDocumentClick(event) {
      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      const anchor = event.target.closest("a");

      if (!anchor || !anchor.href) {
        return;
      }

      if (!("gtag" in window) || typeof window.gtag !== "function") {
        return;
      }

      const intervalId = setInterval(() => {
        if (window.location.href !== anchor.href) {
          return;
        }

        const isHomePage = ["/", ...LOCALES.map((l) => `/${l}/`)].includes(
          window.location.pathname,
        );

        const pageTitle = isHomePage
          ? formatMessage("home-page-title")
          : document.title;

        // eslint-disable-next-line no-console
        console.info(
          `Pushing new data layer entry for page_view event on path (${pageTitle}):`,
          anchor.href,
        );

        if ("gtag" in window && typeof window.gtag === "function") {
          window.gtag("event", "page_view", {
            page_title: pageTitle,
            page_location: anchor.href,
            send_page_view: true,
          });
        }

        clearInterval(intervalId);
      }, 150);
    }

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [formatMessage]);

  return children;
}

export default GtagPageViewProvider;
