import { classList } from "@uniit/frontend-utils";
import IntrinsicElementWrapper from "components-lib/IntrinsicElementWrapper";
import { useField } from "formik";
import React, { useMemo } from "react";
import * as styles from "./Input.module.css";

/**
 * @param {import("react").InputHTMLAttributes<HTMLInputElement> &
 * import("react").TextareaHTMLAttributes<HTMLTextAreaElement> & BaseProps<{
 *  as?: import("components-lib/IntrinsicElementWrapper").IntrinsicElementName;
 *  id: string;
 *  label?: string;
 *  labelProps?: import("react").LabelHTMLAttributes<HTMLLabelElement>;
 *  name: string;
 *  required?: boolean;
 *  type: typeof import("./input-types").default[number],
 * }>} props
 */
function Input({
  as = "input",
  children,
  className = "",
  id,
  label,
  labelProps = {},
  name,
  required = false,
  type = "text",
  ...props
}) {
  const [field, { value }] = useField({ name, type });
  const inputId = useMemo(() => id ?? `${name}-${type}`, [id, name, type]);

  return (
    <label
      className={classList`${styles.inputLabel} ${className}`}
      data-required={required}
      data-type={type}
      htmlFor={inputId}
      {...labelProps}
    >
      {label && (
        <span
          className={classList`${styles.labelText} ${
            required ? styles.required : undefined
          }`}
        >
          {label}
        </span>
      )}
      <IntrinsicElementWrapper
        as={as}
        className={styles.input}
        id={inputId}
        required={required}
        type={type === "toggle" ? "checkbox" : type}
        {...(["checkbox", "toggle"].includes(type) ? { checked: value } : {})}
        {...props}
        {...field}
      >
        {children}
      </IntrinsicElementWrapper>
    </label>
  );
}

export default Input;
