import { Link } from "gatsby";
import {
  LOCALE,
  prependLocaleToPathname,
  useLocale,
} from "internationalization";
import React, { forwardRef } from "react";

const LocalizedLink = forwardRef(
  /**
   * @template T
   * @param {import("gatsby").GatsbyLinkProps<T>} props
   */
  function LocalizedLinkInternal({ to, ...props }, ref) {
    const [locale, { isDefault, isUkrainian }] = useLocale();

    const resolvedTo = isDefault
      ? to
      : isUkrainian
      ? to.split("/").length === 2
        ? prependLocaleToPathname(LOCALE.UA, to)
        : prependLocaleToPathname(LOCALE.EN, to)
      : prependLocaleToPathname(locale, to);

    return <Link {...props} ref={ref} to={resolvedTo} />;
  },
);

export default LocalizedLink;
