import { classList } from "@uniit/frontend-utils";
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkSuperSub from "remark-supersub";
import { limitString } from "shared/utils";
import MarkdownTableCell from "../MarkdownTableCell/MarkdownTableCell";

/**
 * @param {{
 *  className?: string;
 *  children?: string | null;
 *  limit?: number | boolean;
 * }} props
 */
function BaseMarkdown({ className, children, limit }) {
  return (
    <ReactMarkdown
      className={classList`${className} markdown`}
      components={{
        td: MarkdownTableCell,
      }}
      rehypePlugins={[/** @type {any} */ (rehypeRaw)]}
      remarkPlugins={[remarkGfm, remarkSuperSub]}
      // eslint-disable-next-line react/no-children-prop
      children={
        limit
          ? limitString(
              children ?? "",
              typeof limit === "number" ? limit : undefined,
            )
          : children ?? ""
      }
    />
  );
}

export default BaseMarkdown;
