/**
 * Compose a full name.
 *
 * @typedef {object} FullNameParameters
 * @property {string | null} [givenName]
 * @property {string | null} [familyName]
 * @property {string | null} [prefixTitles]
 * @property {string | null} [suffixTitles]
 *
 * @param {FullNameParameters | null} [params]
 * @returns {string}
 */
export function fullName(params) {
  const {
    givenName = "",
    familyName = "",
    prefixTitles = "",
    suffixTitles = "",
  } = params ?? {};

  return [prefixTitles, givenName, familyName, suffixTitles]
    .filter((portion) => portion)
    .join(" ");
}

export function limitString(string = "", limit = 10, lead = "...") {
  return `${string?.substring?.(0, limit)}${
    string?.length > limit ? lead : ""
  }`;
}

/**
 * @param {{ slug?: string | null } | null} [entry]
 */
export function composeSlugPath(entry) {
  if (!entry?.slug) {
    return "/";
  }

  return `/${entry.slug}`;
}

/**
 * @template T
 * @param {readonly T[] | null | undefined} value
 * @returns {{ readonly nodes: readonly NonNullable<T>[] }}
 */
export function toNodes(value) {
  return {
    nodes: /** @type {NonNullable<T>[]} */ ((value ?? []).filter(Boolean)),
  };
}
