import { classList } from "@uniit/frontend-utils";
import React from "react";
import Heading from "../Heading/Heading";
import * as styles from "./Section.module.css";

export const types = undefined;
/**
 * @typedef {import("components-lib/Heading").HeadingProps} HeadingProps
 *
 * @typedef {BaseProps<{
 *  id?: string;
 *  subtitle?: import("react").ReactNode;
 *  title?: import("react").ReactNode;
 *  titleLevel?: HeadingProps["level"];
 *  titleStyle?: HeadingProps["style"]
 * }>} SectionProps
 */

/**
 * @param {SectionProps} props
 */
function Section({
  children,
  className,
  id,
  subtitle,
  title,
  titleLevel = 2,
  titleStyle,
}) {
  return (
    <section className={classList`${styles.section} ${className}`} id={id}>
      {title && (
        <header className={styles.header}>
          <Heading
            className={styles.heading}
            level={titleLevel}
            style={titleStyle}
          >
            {title}
          </Heading>
          <hr />
          {(typeof subtitle === "string"
            ? subtitle.trim().length > 0
            : Boolean(subtitle)) && <div>{subtitle}</div>}
        </header>
      )}
      {children}
    </section>
  );
}

export default Section;
