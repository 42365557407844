/* eslint-disable react/button-has-type */
import { classList } from "@uniit/frontend-utils";
import messageIcon from "images/icons/message.svg";
import { useFormatMessage } from "internationalization";
import React from "react";
import * as styles from "./Button.module.css";

/**
 * @typedef {import("react").ButtonHTMLAttributes<HTMLButtonElement>} ButtonProps
 * @param {ButtonProps} props
 */
function Button({ children, className, ...props }) {
  return (
    <button className={classList`${className} ${styles.button}`} {...props}>
      {children}
    </button>
  );
}

/**
 * @param {ButtonProps} props
 */
export function SendButton({ children, className, ...props }) {
  const formatMessage = useFormatMessage();

  return (
    <Button className={classList`${className} ${styles.sendButton}`} {...props}>
      {children}
      <img alt={formatMessage("message-icon")} src={messageIcon} />
    </Button>
  );
}

/**
 * @param {ButtonProps} props
 */
export function OutlinedButton({ children, className, ...props }) {
  return (
    <Button
      className={classList`${className} ${styles.outlinedButton}`}
      {...props}
    >
      {children}
    </Button>
  );
}

export default Button;
