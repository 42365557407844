import { navigate } from "gatsby";
import { noop } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useIntl } from "react-intl";
import { NAV_LINK_TRANSLATIONS } from "shared/constants";
import { LOCALE, LOCALE_MAP, DEFAULT_LOCALE } from "./shared";
import en from "./translations/en.json";
import sk from "./translations/sk.json";
import ua from "./translations/ua.json";

export * from "./shared";

const MESSAGES = { en, sk, ua };

export const LocaleContext = createContext(
  /** @type {UseState<Locale>} */ ([DEFAULT_LOCALE, noop]),
);

export const PageI18nContext = createContext(
  /** @type {PageI18nContext} */ ({
    langKey: DEFAULT_LOCALE,
  }),
);

/**
 * @typedef {{ isDefault: boolean; isUkrainian: boolean; }} LocaleInfo
 * @typedef {[Locale, { isDefault: boolean; isUkrainian: boolean; mappedLocale: string }]} CurrentLocaleInfo
 * @typedef {keyof typeof en} MessageKey
 */

export const LOCALES = Object.values(LOCALE);

export function usePageI18nContext() {
  return useContext(PageI18nContext);
}

/** @returns {CurrentLocaleInfo} */
export function useLocale() {
  const defaultLocale = DEFAULT_LOCALE;
  const [locale, setLocale] = useContext(LocaleContext);

  const resolvedLocale = locale || defaultLocale;
  const mappedLocale = LOCALE_MAP[resolvedLocale];

  useEffect(() => {
    if (!locale) {
      const pathnameLocale = /** @type {Locale} */ (
        window.location.pathname.split("/")[1]
      );

      if (pathnameLocale && LOCALES.includes(pathnameLocale)) {
        setLocale(pathnameLocale);
      }
    }
  }, [locale, setLocale]);

  return [
    resolvedLocale,
    {
      isDefault: resolvedLocale === defaultLocale,
      isUkrainian: resolvedLocale === "ua",
      mappedLocale,
    },
  ];
}

/** @returns {Locale} */
export function useDefaultLocale() {
  return /** @type {Locale} */ (DEFAULT_LOCALE);
}

/** @returns {boolean} */
export function useIsUkrainian() {
  const [, { isUkrainian }] = useLocale();
  return isUkrainian;
}

export function useFormatMessage() {
  const { formatMessage } = useIntl();

  return useCallback(
    /**
     * @param {MessageKey} id
     * @param {Record<string, string>} [values]
     */
    (id, values) => formatMessage({ id }, values),
    [formatMessage],
  );
}

/**
 * @param {Locale} locale
 * @returns {(typeof MESSAGES)[Locale]}
 */
export function useMessages(locale) {
  return useMemo(() => MESSAGES[locale], [locale]);
}

/**
 * @param {Locale} [localeOverride]
 * @returns {{ [Key in keyof typeof NAV_LINK_TRANSLATIONS["en"]]: string; }}
 */
export function useLocalizedNavLinks(localeOverride) {
  const [locale] = useLocale();

  const resolvedLocale = localeOverride || locale;

  return resolvedLocale === "ua"
    ? NAV_LINK_TRANSLATIONS.en
    : NAV_LINK_TRANSLATIONS[resolvedLocale];
}

export function useLocalizePath() {
  const [locale, { isDefault }] = useLocale();

  const navLinks = useLocalizedNavLinks();

  return useCallback(
    /**
     * @param {string} path
     * @returns {string}
     */
    (path) => {
      let pathname =
        (path === "home"
          ? "/"
          : path in navLinks
          ? navLinks[/** @type {keyof typeof navLinks} */ (path)]
          : path) || "";

      if (pathname.startsWith("/")) {
        pathname = pathname.replace("/", "");
      }

      return `${isDefault ? "" : `/${locale}`}/${pathname}`;
    },
    [isDefault, locale, navLinks],
  );
}

/**
 * @param {Locale} locale
 * @param {string} pathname
 */
export function prependLocaleToPathname(locale, pathname) {
  return `/${locale}${pathname.startsWith("/") ? pathname : `/${pathname}`}`;
}

/**
 * @param {string} to
 * @param {Locale} locale
 * @param {LocaleInfo} info
 */
export function resolveLocalizedTo(to, locale, { isDefault, isUkrainian }) {
  const resolvedTo = isDefault
    ? to
    : isUkrainian
    ? to.split("/").length === 2
      ? prependLocaleToPathname(LOCALE.UA, to)
      : prependLocaleToPathname(LOCALE.EN, to)
    : prependLocaleToPathname(locale, to);

  return resolvedTo;
}

/**
 * @param {string} to
 */
export function useLocalizedTo(to) {
  const localeInfo = useLocale();
  return resolveLocalizedTo(to, ...localeInfo);
}

export function useLocalizedNavigate() {
  const localeInfo = useLocale();

  const localizedNavigate = useCallback(
    /**
     * @param {string} to
     * @param {import("@reach/router").NavigateOptions<{}>} [options]
     */
    (to, options) => {
      navigate(resolveLocalizedTo(to, ...localeInfo), options);
    },
    [localeInfo],
  );

  return localizedNavigate;
}
