import { classList } from "@uniit/frontend-utils";
import { graphql, navigate, useStaticQuery } from "gatsby";
import {
  DEFAULT_LOCALE,
  LOCALE,
  LOCALES,
  LOCALE_MAP,
  useFormatMessage,
  useLocale,
  usePageI18nContext,
} from "internationalization";
import React, { useCallback, useMemo } from "react";
import { NAV_LINK_TRANSLATIONS } from "shared/constants";
import * as styles from "./LocaleSwitcher.module.css";

const LOCALE_REGEX = new RegExp(
  LOCALES.map((locale) => `^/${locale}/?`).join("|"),
);

function delocalizePathname() {
  return window.location.pathname
    .replace(LOCALE_REGEX, "")
    .replace(/^\/|\/$/g, "");
}

/**
 * @param {BaseProps} props
 */
function LocaleSwitcher({ className, tabIndex }) {
  const [currentLocale] = useLocale();
  const { localizations } = usePageI18nContext();

  const formatMessage = useFormatMessage();

  /** @type {Queries.UkrainianLocaleQuery} */
  const data = useStaticQuery(graphql`
    query UkrainianLocale {
      homePage: strapiHomePage {
        uaLocale
      }
    }
  `);

  const uaLocaleEnabled = Boolean(data.homePage?.uaLocale);

  const filteredLocales = useMemo(
    () =>
      LOCALES.filter((locale) =>
        locale === LOCALE.UA ? uaLocaleEnabled : true,
      ),
    [uaLocaleEnabled],
  );

  const handleLocaleChange = useCallback(
    /**
     * @param {Locale} locale
     */
    (locale) => {
      if (locale === currentLocale) {
        return;
      }

      if (locale === LOCALE.UA) {
        navigate(`/${LOCALE.UA}`);
        return;
      }

      const delocalizedPathname = delocalizePathname();

      const [currentNavKey] =
        Object.entries(NAV_LINK_TRANSLATIONS[currentLocale]).find(
          ([, path]) => path === `/${delocalizedPathname}`,
        ) ?? [];

      let resolvedPath = currentNavKey
        ? NAV_LINK_TRANSLATIONS[locale][
            /** @type {keyof typeof NAV_LINK_TRANSLATIONS.en} */ (currentNavKey)
          ].replace("/", "")
        : null;

      if (!resolvedPath && localizations) {
        const matchingLocalization = localizations.find(
          (localization) => localization?.locale === LOCALE_MAP[locale],
        );

        resolvedPath = matchingLocalization?.slug ?? delocalizedPathname;
      } else if (!resolvedPath) {
        resolvedPath = delocalizedPathname;
      }

      const pathname =
        locale !== DEFAULT_LOCALE
          ? `/${locale}/${resolvedPath}`
          : `/${resolvedPath}`;

      navigate(pathname);
    },
    [currentLocale, localizations],
  );

  return (
    <section className={classList`${className} ${styles.languageSwitcher}`}>
      {filteredLocales.map((locale, index) => (
        <React.Fragment key={`locale-${locale}`}>
          <button
            aria-label={formatMessage("language-switch-aria-label", {
              language: locale,
            })}
            className={styles.localeOption}
            data-current={locale === currentLocale}
            onClick={() => handleLocaleChange(locale)}
            tabIndex={tabIndex}
            type="button"
          >
            {locale}
          </button>
          {index < filteredLocales.length - 1 && <span>|</span>}
        </React.Fragment>
      ))}
    </section>
  );
}

export default LocaleSwitcher;
