import { formatTeamMemberName } from "components/TeamMemberName";
import LocalizedLink from "components-lib/LocalizedLink";
import { graphql } from "gatsby";
import SearchIcon from "images/icons/Search";
import { useFormatMessage } from "internationalization";
import React, { useCallback, useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { composeSlugPath } from "shared/utils";
import * as styles from "./Search.module.css";
import useSearch from "./useSearch";

const SEARCH_RESULT_TYPES = {
  STRAPI_ARTICLE: "article",
  STRAPI_TEAM_MEMBER: "team-member",
  STRAPI_SERVICE: "service",
};

/**
 * @template T
 * @param {readonly T[] | undefined | null} array
 * @param {(entry: T) => string | undefined | null} getLabel
 * @returns {(T & { label: string; })[]}
 */
function toSearchEntries(array, getLabel) {
  return (array ?? []).map((entry) => ({
    ...entry,
    label: getLabel(entry) ?? "",
  }));
}

/**
 * @param {DataProps<Partial<Queries.SearchQueriesFragment>> & {
 *   open?: boolean;
 *   onClose?: () => void;
 * }} props
 */
function Search({ data, open: isOpen, onClose }) {
  const [searchInput, setSearchInput] = useState("");

  const searchBackDropRef = useRef(/** @type {HTMLDivElement | null} */ (null));
  const searchDialogRef = useRef(
    /** @type {HTMLDialogElement | null} */ (null),
  );
  const searchInputRef = useRef(/** @type {HTMLInputElement | null} */ (null));

  const formatMessage = useFormatMessage();

  const [search, searchResults, setSearchResults] = useSearch([
    ...toSearchEntries(data?.searchArticles?.nodes, (article) => article.title),
    ...toSearchEntries(data?.searchServices?.nodes, (service) => service.name),
    ...toSearchEntries(data?.searchTeamMembers?.nodes, formatTeamMemberName),
  ]);

  const handleSearch = useCallback(
    (input = "") => {
      setSearchInput(input);
      setSearchResults(search(input));
    },
    [search, setSearchResults],
  );

  const handleClose = useCallback(() => {
    searchDialogRef.current?.close();
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      searchDialogRef.current?.show();
      searchInputRef.current?.focus();
    } else {
      searchDialogRef.current?.close();
    }
  }, [isOpen]);

  return (
    <dialog className={styles.searchDialog} ref={searchDialogRef}>
      <div>
        <SearchIcon className={styles.searchIcon} />
        <input
          ref={searchInputRef}
          type="text"
          className={styles.searchInput}
          placeholder={formatMessage("input-your-search")}
          onChange={(event) => {
            handleSearch(event.target.value);
          }}
          value={searchInput}
        />
      </div>
      <ul className={styles.searchResults}>
        {searchResults.slice(0, 4).map((result, index) => {
          return (
            <LocalizedLink
              key={`result-number-${index}`}
              to={composeSlugPath({ slug: result.item.fields?.slug })}
            >
              <li className={styles.searchResult}>
                {result.item.label}
                <form method="dialog">
                  <button className={styles.searchResultButton} type="submit">
                    <FormattedMessage
                      id={
                        SEARCH_RESULT_TYPES[
                          /** @type {keyof typeof SEARCH_RESULT_TYPES} */ (
                            result.item.internal.type
                          )
                        ]
                      }
                    />
                  </button>
                </form>
              </li>
            </LocalizedLink>
          );
        })}
      </ul>
      <div
        className={styles.backdrop}
        ref={searchBackDropRef}
        onClick={handleClose}
      />
    </dialog>
  );
}

export const query = graphql`
  fragment ArticleSearchNodes on STRAPI_ARTICLEConnection {
    nodes {
      title
      internal {
        type
      }
      fields {
        slug
      }
    }
  }
  fragment TeamMemberSearchNodes on STRAPI_TEAM_MEMBERConnection {
    nodes {
      ...TeamMemberNameParts
      internal {
        type
      }
      fields {
        slug
      }
    }
  }
  fragment ServiceSearchNodes on STRAPI_SERVICEConnection {
    nodes {
      name
      internal {
        type
      }
      fields {
        slug
      }
    }
  }
  fragment SearchQueries on Query {
    searchArticles: allStrapiArticle(filter: { locale: { eq: $langKey } }) {
      ...ArticleSearchNodes
    }
    searchTeamMembers: allStrapiTeamMember(
      filter: { locale: { eq: $langKey } }
      sort: { order: ASC }
    ) {
      ...TeamMemberSearchNodes
    }
    searchServices: allStrapiService(
      filter: { locale: { eq: $langKey } }
      sort: { order: ASC }
    ) {
      ...ServiceSearchNodes
    }
  }
`;

export default Search;
